import { Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import Contact from "./Contact";

export default function MainContent() {
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                mt: 2
            }}
        >
            <Typography
                component="h1"
                variant="h4"
            >
                Martin Rempel
            </Typography>
            <Typography component="h2" variant="h6" sx={{ fontWeight: "light" }}>
                {"Software Developer at "}
                <Link
                    href="https://sap.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                >
                    SAP SE
                </Link>
                {" in "}
                <Link
                    href="https://www.google.com/maps/place/Germany/"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    color="inherit"
                >
                    Germany
                </Link>
                {"."}
            </Typography>
            <Contact />
        </Box>
    );
};