import { Policy } from "@mui/icons-material";
import { Button, IconButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";

export default function Footer() {
    const buttonRef = useRef(null);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const openPopover = () => setIsPopoverOpen(true);
    const closePopover = () => setIsPopoverOpen(false);

    return (
        <Box
            component="footer"
            sx={{
                pb: 2,
                display: "flex"
            }}
        >
            <IconButton
                ref={buttonRef}
                onClick={openPopover}
                aria-label="Legal Disclosure"
                sx={{
                    marginLeft: "auto"
                }}
            >
                <Policy />
            </IconButton>
            <Popover
                open={isPopoverOpen}
                onClose={closePopover}
                anchorEl={buttonRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ m: 2 }}>
                    <Typography>Martin Rempel</Typography>
                    <Typography>Weismantelweg 20</Typography>
                    <Typography>51109 Köln</Typography>
                </Box>
            </Popover>
        </Box>
    );
};