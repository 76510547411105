import { Email, LinkedIn, Twitter } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";

export default function Contact() {
    const triggerEmail = () => {
        window.location.href = "nbjmup;nbjmAnbsujosfnqfm/ef".split("")
            .map(c => c.charCodeAt(0))
            .map(c => c >= 8364 ? 128 : c)
            .map(c => String.fromCharCode(c - (1)))
            .join("");
    };

    return (
        <Box>
            <IconButton
                color="primary"
                aria-label="E-Mail"
                onClick={triggerEmail}>
                <Email />
            </IconButton>
            <IconButton
                color="primary"
                aria-label="Twitter"
                href="https://www.twitter.com/martin_rempel"
                target="_blank"
                rel="noopener noreferrer">
                <Twitter />
            </IconButton>
            <IconButton
                color="primary"
                aria-label="Linked-In"
                href="https://www.linkedin.com/in/martin-rempel"
                target="_blank"
                rel="noopener noreferrer">
                <LinkedIn />
            </IconButton>
        </Box>
    );
};